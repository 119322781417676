import React, { useEffect, useRef, useState } from 'react';
import ConnectElectric from '../ConnectElectric';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withPrefix } from 'gatsby';
import { navigate } from 'gatsby-link';
import { useQueryParam } from 'gatsby-query-params';
import {
  Parallax,
  ParallaxBanner,
  ParallaxProvider
} from 'react-scroll-parallax';
import {
  ArrowForward,
  ChevronRight,
  ExpandMore,
  Info,
  InfoOutlined
} from '@mui/icons-material';

import {
  saveServiceAddress,
  getCurrentRoute,
  getCookie,
  scrollToElementInPage
} from '../../api/dataApi';

import * as webSocketActions from '../../redux/actions/webSocketActions';
import * as serviceAddressActions from '../../redux/actions/serviceAddressActions';

import { electricData } from '../../mock/electric';

import AddressSearch from '../AddressSearch';
import AddressSearchPopup from '../AddressSearch/AddressSearchPopup';
import Electric6MonthAvgCompare from '../Electric6MonthAvgCompare';
import TermTypePopup from '../ConnectElectric/TermTypePopup';

import './index.css';
import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from '@mui/material';
import ProofOfServiceExamplePopup from './ProofOfServiceExamplePopup';
import ContactForm from '../ContactForm';
import Landing_2024 from '../Landing_2024';

export function ProviderLogos() {
  return (
    <div className="provider-logos">
      <img
        src="https://sc-cdn-public.s3.us-west-2.amazonaws.com/75593_Email_reliant-energy-logos-ideRUj2Mu-.svg"
        alt="Reliant Energy"
      />
      <img
        src="https://sc-cdn-public.s3.us-west-2.amazonaws.com/76651_gme2.svg"
        alt="Green Mountain Energy"
      />
      <img
        src="https://sc-cdn-public.s3.us-west-2.amazonaws.com/75597_Email_DirectEnergy.png"
        alt="Direct Energy"
      />{' '}
      <img
        src="https://sc-cdn-public.s3.us-west-2.amazonaws.com/76351_Email_drawing.svg"
        alt="Cirro Energy"
      />
      <img
        src="https://sc-cdn-public.s3.us-west-2.amazonaws.com/85647_Email_rhythm-logo.svg"
        alt="Rhythm Energy"
      />
      <img
        src="https://sc-cdn-public.s3.us-west-2.amazonaws.com/85755_Email_65da7cc6c0892ef66dfec234_OhmConnect-Logo%201.svg"
        alt="OhmConnect"
      />
      {/* <img
        src="https://sc-cdn-public.s3.us-west-2.amazonaws.com/77671_Veteran%20Energy.svg"
        alt="Veteran Energy"
      />
      <img
        src="https://sc-cdn-public.s3.us-west-2.amazonaws.com/85467_express-logo.svg"
        alt="Express Energy"
      /> */}
      <img
        src="https://sc-cdn-public.s3.us-west-2.amazonaws.com/76612_gexa_logo_stacked_hires_(color)_trans_back.svg"
        alt="Gexa Energy"
      />
      <img
        src="https://sc-cdn-public.s3.us-west-2.amazonaws.com/76071_Frontier-Utilities-Corporate-Logo.svg"
        alt="Frontier Utilities"
      />
      {/* <img
        src="https://sc-cdn-public.s3.us-west-2.amazonaws.com/77690_4change_energy_logoV2-01.svg"
        alt="4Change Energy"
      /> */}
      <img
        src="https://sc-cdn-public.s3.us-west-2.amazonaws.com/85468_logo-payless-color.svg"
        alt="Payless Power"
      />
      <img
        src="https://sc-cdn-public.s3.us-west-2.amazonaws.com/76077_dp2.svg"
        alt="Discount Power"
      />
      <img
        src="https://sc-cdn-public.s3.us-west-2.amazonaws.com/AE-Texas-Logo-w-Leaf-1024x464.png"
        alt="AETexas"
      />
    </div>
  );
}

function FAQ({ question, answer }) {
  const [expanded, setExpanded] = useState(false);

  return (
    <div
      itemscope
      itemprop="mainEntity"
      itemtype="https://schema.org/Question"
      className={'question' + (expanded ? ' expanded' : '')}>
      <h5 itemprop="name" onClick={() => setExpanded(!expanded)}>
        {question}{' '}
        <IconButton>
          <ExpandMore />
        </IconButton>
      </h5>
      <div
        itemscope
        itemprop="acceptedAnswer"
        itemtype="https://schema.org/Answer">
        <p itemprop="text">{answer}</p>
      </div>
    </div>
  );
}

function SCLandingWrapper({
  actions,
  city,
  webSocket,
  serviceAddress,
  siteConfig,
  noHeader,
  preFilter,
  preFilterLabel,
  headerIfName
}) {
  const checking = useRef();
  const initted = useRef();
  const [showAddressSearch, setShowAddressSearch] = useState(null);
  const [hasZipStored, setHasZipStored] = useState(getCookie('sc-zip-code'));
  const [hideHeader, setHideHeader] = useState(null);
  const uc = useQueryParam('uc');
  const [show6MonthAvg, setShow6MonthAvg] = useState(null);
  const [showSummer, setShowSummer] = useState(null);
  const [showTermTypePopup, setShowTermTypePopup] = useState(null);
  const [showProofOfServiceSample, setShowProofOfServiceSample] =
    useState(false);

  const simple = useQueryParam('simple') === '1';
  const zipEntered = useQueryParam('zipEntered') === '1';
  const scrollTimeout = useRef();

  const [request, setRequest] = useState({});
  const [requestedData, setRequestedData] = useState({});

  useEffect(() => {
    actions.setLandingPage({ route: getCurrentRoute(), city });
    if (hasZipStored) {
      actions.pageLoading(true, 'Checking rates...');
    }
    //actions.serviceAddressUpdated({ ...electricData[city], mock: true });
  }, []);

  useEffect(() => {
    if (zipEntered) {
      setHideHeader(true);
    } else {
      setHideHeader(false);
    }
    console.log('zip entered', zipEntered);
  }, [zipEntered]);

  useEffect(() => {
    if (
      uc &&
      uc === 'IG-Aff' &&
      webSocket &&
      webSocket.initted &&
      webSocket.ajaxConnected &&
      !show6MonthAvg
    ) {
      setShow6MonthAvg(true);
    }
  }, [uc, webSocket]);

  useEffect(() => {
    if (
      uc &&
      uc === 'IG-Summer' &&
      webSocket &&
      webSocket.initted &&
      webSocket.ajaxConnected &&
      !showSummer
    ) {
      setShowSummer(true);
    }
  }, [uc, webSocket]);

  useEffect(() => {
    if (
      webSocket &&
      webSocket.initted &&
      webSocket.ajaxConnected &&
      !webSocket.hasServiceAddress &&
      !checking.current &&
      (!serviceAddress || serviceAddress.mock) &&
      city
    ) {
      checking.current = true;

      actions.pageLoading(true, 'Checking rates...');

      saveServiceAddress('', '', city, 'TX', '', 11).then((result) => {
        actions.serviceAddressUpdated({ ...result, force: true });
        actions.pageLoading(false);
      });
    }
  }, [webSocket]);

  useEffect(() => {
    if (
      serviceAddress &&
      serviceAddress.Address &&
      serviceAddress.Address.Zip
    ) {
      if (!hasZipStored && (getCookie('sc-zip-code') || city)) {
        scrollTimeout.current = setTimeout(() => {
          setHasZipStored(true);
          scrollToPlans(false);
        }, 500);
      }

      actions.pageLoading(false);
    }
  }, [serviceAddress]);

  useEffect(() => {
    const observerOptions = {
      root: null, // viewport
      threshold: 0.2 // trigger when X% of the element is visible
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && entry.target.id) {
          entry.target.classList.add('in-view');

          const connector = document.getElementById(
            'connector' + entry.target.id.split('point')[1]
          );
          if (connector) {
            connector.classList.add('in-view');
          }
        }
      });
    }, observerOptions);

    // Target all .point elements with an ID
    const pointsWithIds = document.querySelectorAll('.point[id]');
    pointsWithIds.forEach((point) => observer.observe(point));

    // Cleanup observer on component unmount
    return () => {
      pointsWithIds.forEach((point) => observer.unobserve(point));
    };
  }, []);

  function scrollToPlans(instant, offset) {
    const bestHeader = document.getElementsByClassName('best-header')[0];
    const offerRow = document.getElementsByClassName('offer-row')[0];
    if (bestHeader && offerRow) {
      scrollToElementInPage(bestHeader, offset ? offset : -280, instant);
    }
  }

  if (typeof window === 'undefined') {
    actions.serviceAddressUpdated({ ...electricData[city], mock: true });
  }

  return (
    <div
      className={
        'sc-landing-wrapper sc-landing-2024 sc-landing-2025' +
        (hasZipStored &&
        (!serviceAddress ||
          !serviceAddress.Address ||
          !serviceAddress.Address.Zip)
          ? ' with-zip'
          : '') +
        (hasZipStored ||
        (serviceAddress && serviceAddress.Address && serviceAddress.Address.Zip)
          ? ' with-offers'
          : '') +
        (hideHeader ? ' hide-header' : '')
      }>
      {show6MonthAvg &&
      (!serviceAddress ||
        !serviceAddress.Address ||
        !serviceAddress.Address.Zip) ? (
        <Electric6MonthAvgCompare />
      ) : null}
      {(!noHeader && !hideHeader && !headerIfName) ||
      (headerIfName &&
        serviceAddress &&
        serviceAddress.Address &&
        serviceAddress.Address.FirstName) ? (
        <div className="hero">
          {showSummer ? (
            <div className="summer-image">
              <img src={withPrefix('/images/misc/happy-sun.png')} />
            </div>
          ) : null}

          {headerIfName &&
          serviceAddress.Address &&
          serviceAddress.Address.FirstName &&
          serviceAddress.Address.Address ? (
            <div className="property-welcome">
              <h2>Hello, {serviceAddress.Address.FirstName}!</h2>
              {serviceAddress.Address.PropertyName ? (
                <>
                  <h3>Welcome to {serviceAddress.Address.PropertyName}</h3>
                  <p>
                    Your community has partnered with {siteConfig.brand} to help
                    you find a great electricity plan for your new home. Plus,
                    if you set up electric service through {siteConfig.brand},
                    we will automatically send the Proof of Service
                    documentation required by your lease to the property so you
                    don't have to.
                  </p>
                  <h4>Showing electric plans for:&nbsp;&nbsp;</h4>
                </>
              ) : null}

              <h5>
                {serviceAddress.Address.Address}{' '}
                {serviceAddress.Address.Secondary}
                <br />
                {serviceAddress.Address.City}, {serviceAddress.Address.State}{' '}
                {serviceAddress.Address.Zip}
              </h5>
            </div>
          ) : (
            <>
              <h2>
                <strong>
                  Show your residents that you <em>care</em> by helping them
                  find a great deal on electricity.
                </strong>
                <br />
                And add to your bottom-line in the process!
              </h2>
            </>
          )}
        </div>
      ) : null}

      <div className="sc-landing-2025">
        <section className="earn-more">
          <h2>Earn more on every enrollment</h2>
          <h3>
            Why limit your ancillary income to a few plans from a few providers
            when you can earn a commission on any plan from any provider?
          </h3>
        </section>

        <section className="partner">
          <h3>
            Partner with SmarterChoice and 100% of the fees below will go to
            you!
          </h3>
          <p>
            Compare the payouts that we offer to what you're currently getting
            and you'll see that we pay more per enrollment than anyone else - a
            lot more!
          </p>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Provider</TableCell>
                <TableCell>Commission Paid to You</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Reliant</TableCell>
                <TableCell>$100.00</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Green Mountain</TableCell>
                <TableCell>$100.00</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Direct Energy</TableCell>
                <TableCell>$100.00</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>OhmConnect</TableCell>
                <TableCell>$100.00</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Atlantex</TableCell>
                <TableCell>$100.00</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Payless Power</TableCell>
                <TableCell>$120.00</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Gexa Energy</TableCell>
                <TableCell>$85.00</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Frontier Utilities</TableCell>
                <TableCell>$85.00</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Rhythm</TableCell>
                <TableCell>$85.00</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Cirro</TableCell>
                <TableCell>$75.00</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Discount Power</TableCell>
                <TableCell>$75.00</TableCell>
              </TableRow>
            </TableBody>
          </Table>

          <ProviderLogos />
        </section>

        <section className="existing-agreement">
          <h2>Already have a 'Preferred Provider' agreement?</h2>
          <h3>
            No problem! SmarterChoice can work in harmony with your existing
            agreement to boost resident engagement and increase your ancillary
            income.
          </h3>
        </section>

        <section className="enhancement">
          <h3>A non-disruptive enhancement to your existing process.</h3>
          <p>
            You can add SmarterChoice without making any changes to your
            existing process. You continue doing what you’re contractually
            obligated to do under your current agreement, exactly what you’re
            doing now. Those residents that choose to enroll with your Preferred
            Provider through your outreach will do so - but many won’t. Those
            that don’t probably know they can choose any electric provider they
            want and will likely opt to use a more comprehensive online shopping
            site that offers them a broader selection of plans and providers
            than just your Preferred Provider.
          </p>
          <p className="stand-out">
            That’s where SmarterChoice comes in. We provide residents who prefer
            to shop elsewhere the opportunity to do so. By combining
            SmarterChoice with your existing Preferred Provider program, you can
            effectively serve both groups and boost your ancillary income by
            increasing overall enrollment rates.{' '}
          </p>
          <p>
            And since SmarterChoice reaches out to each resident directly,
            independent of what your onsite teams are doing, you’re not in
            violation of the exclusivity clause of your existing agreement
          </p>
        </section>

        <section className="pms-logos">
          <div className="provider-logos">
            <img src={withPrefix('/images/software/yardi.svg')} alt="Yardi" />
            <img
              src={withPrefix('/images/software/realpage.svg')}
              alt="RealPage"
            />
            <img src={withPrefix('/images/software/resman.svg')} alt="ResMan" />
            <img
              src={withPrefix('/images/software/entrata.svg')}
              alt="Entrata"
            />
            <img
              src={withPrefix('/images/software/appfolio.svg')}
              alt="AppFolio"
            />
          </div>
        </section>

        <section className="reporting">
          <h2>Our online reporting provides unparalleled insight</h2>
          <h3>
            Everything you might want to or need to know is easily accessible
            through your Portfolio Dashboard.
          </h3>
          <img
            src={withPrefix('/images/sc-landing/2025_port_v2.png')}
            alt="Portfolio Dashboard"
            className="screenshot"
            style={{ marginBottom: '50px' }}
          />
          <h2>Property Specific Dashboards</h2>
          <h3>
            Detailed information on each individual move-in is available to you
            through our Property Specific Dashboards.
          </h3>
          <p>
            What is your current enrollment rate? Are you getting credit for
            every enrollment with your current partner? Are you sure? With
            SmarterChoice’s resident level reporting you can easily track our
            performance and audit every new move-in, every enrollment, and every
            commission earned and paid.
          </p>
          <img
            src={withPrefix('/images/sc-landing/2025_prop.png')}
            alt="Property Dashboard"
            className="screenshot"
          />
        </section>

        <section className="resident-outreach">
          <h2>Resident Outreach</h2>
          <h3>
            Let SmarterChoice manage the entire process, so your onsite teams
            don't have to.
          </h3>
        </section>

        <section className="resident">
          <h3>A better resident experience!</h3>
          <p>
            From our friendly invitation, to our helpful and easy-to-use
            shopping platform, SmarterChoice makes the entire resident journey
            quick and easy. We even send their Proof of Electric Service
            enrollment directly to the property so they don’t have to.
          </p>

          <div className="screenshots">
            <img
              src={withPrefix('/images/sc-landing/2025_resident.png')}
              alt="Resident Energy Dashboard"
            />
          </div>
        </section>

        <section className="shopping">
          <h2>The resident shopping experience</h2>
          <h3>
            Enter any Texas address below to experience the SmarterChoice.com
            shopping platform yourself.
          </h3>
        </section>
      </div>

      <Landing_2024 />

      <div className="sc-landing-2025">
        <section className="contact">
          <h3>Want to know more?</h3>
          <p>
            Email:{' '}
            <a href="mailto:support@smarterchoice.com">
              support@smarterchoice.com
            </a>
          </p>
          <p>
            Phone: <a href="tel:2147703060">(214) 770-3060</a>
          </p>
          <p>Fill out the form below and we'll get back to you.</p>
          <ContactForm
            subject={'Contact ' + siteConfig.brand}
            request={request}
            fullWidth
            setRequest={setRequest}
            fields={
              <>
                <TextField
                  variant="outlined"
                  label="Phone"
                  fullWidth
                  value={request['Phone']}
                  onChange={(event) =>
                    setRequest({ ...request, ['Phone']: event.target.value })
                  }
                  required
                />

                <TextField
                  variant="outlined"
                  label="Message"
                  fullWidth
                  value={request['Message']}
                  multiline={true}
                  onChange={(event) =>
                    setRequest({ ...request, ['Message']: event.target.value })
                  }
                  required
                />
              </>
            }
          />
        </section>
      </div>

      {showAddressSearch ? (
        <AddressSearchPopup
          mode="zip"
          closeFunc={() => setShowAddressSearch(null)}
          afterAddressSelected={() => {
            //do nothing, stay here
            setShowAddressSearch(null);
          }}
        />
      ) : null}

      {showTermTypePopup ? (
        <TermTypePopup
          closeFunc={() => {
            setShowTermTypePopup(false);
          }}
          title={'Contract Cancellation'}
        />
      ) : null}

      {showProofOfServiceSample ? (
        <ProofOfServiceExamplePopup
          closeFunc={() => {
            setShowProofOfServiceSample(false);
          }}
          title={'Proof of Service Example'}
        />
      ) : null}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    siteConfig: state.commonData.siteConfig
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch),
      setLandingPage: bindActionCreators(
        webSocketActions.setLandingPage,
        dispatch
      ),
      serviceAddressUpdated: bindActionCreators(
        serviceAddressActions.serviceAddressUpdated,
        dispatch
      )
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SCLandingWrapper);
