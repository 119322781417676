function getSiteConfig() {
  let siteConfig = {
    id: process.env.GATSBY_BRAND_ID
  };

  switch (siteConfig.id) {
    case 'pts':
      siteConfig.brand = 'Power to Save';
      siteConfig.logo = '/images/pts-logo.svg';
      siteConfig.appleTouchIcon = '/images/pts-logo.svg';
      siteConfig.logoMaxWidth = '300px';
      siteConfig.logoWidth = '100%';
      siteConfig.favIcon = 'images/pts-favicon.ico';
      siteConfig.domain = 'powertosave.com';
      siteConfig.phone = '(888) 969-4367';
      siteConfig.email = 'support@powertosave.com';
      break;
    case 'ptg':
      siteConfig.brand = 'Power to Give';
      siteConfig.logo = '/images/ptg-logo.svg';
      siteConfig.appleTouchIcon = '/images/ptg-logo.svg';
      siteConfig.logoMaxWidth = '300px';
      siteConfig.logoWidtZh = '100%';
      siteConfig.favIcon = '/images/ptg-favicon.ico';
      siteConfig.domain = 'powertogive.com';
      siteConfig.phone = '(888) 969-4367';
      siteConfig.email = 'support@powertogive.com';
      break;
    case 'sc':
      siteConfig.brand = 'SmarterChoice';
      siteConfig.logo = '/images/sc-logo-with-tagline.svg';
      siteConfig.logoFooter = '/images/sc-logo-with-tagline-white.svg';
      siteConfig.appleTouchIcon = '/images/sc-logo-icon.png';
      siteConfig.logoMaxWidth = '250px';
      siteConfig.logoWidtZh = '100%';
      siteConfig.favIcon = 'sc-favicon.ico';
      siteConfig.logoWhite = '/images/sc-logo-white.svg';
      siteConfig.domain = 'SmarterChoice.com';
      siteConfig.copyright = 'SmarterChoice LLC';
      siteConfig.tagline = 'Find your best electric rate in Texas';
      siteConfig.phone = '(214) 770-3060';
      siteConfig.email = 'support@smarterchoice.com';
      siteConfig.txLicense = 'BR210238';
      break;
    case 'mi':
      siteConfig.brand = 'MovingIN';
      siteConfig.logo = '/images/logo.svg';
      siteConfig.favIcon = 'favicon.ico';
      siteConfig.appleTouchIcon = '/images/logo192.png';
      siteConfig.logoMaxWidth = '150px';
      siteConfig.logoWhite = '/images/mi-logo-white.svg';
      siteConfig.domain = 'movingin.com';
      siteConfig.copyright = 'MovingIN LLC';
      siteConfig.requireInvitation = true;
      siteConfig.phone = '(888) 969-4367';
      siteConfig.email = 'support@movingin.com';
      siteConfig.txLicense = 'BR210178';
      break;
    default:
      break;
  }

  return siteConfig;
}

export default {
  ThemeOptions: {},
  apiCallsInProgress: 0,
  webSocket: {
    connecting: false,
    connected: false,
    disconnected: false,
    connectionId: null,
    isAuthenticated: false,
    pageLoading: false,
    loadingMessage: null
  },
  authUser: null,
  serviceAddress: null,
  residentOnboarding: null,
  partnerData: null,
  commonData: {
    siteConfig: getSiteConfig(),
    stateList: [
      'AK',
      'AL',
      'AR',
      'AZ',
      'CA',
      'CO',
      'CT',
      'DC',
      'DE',
      'FL',
      'GA',
      'HI',
      'IA',
      'ID',
      'IL',
      'IN',
      'KS',
      'KY',
      'LA',
      'MA',
      'MD',
      'ME',
      'MI',
      'MN',
      'MO',
      'MS',
      'MT',
      'NC',
      'ND',
      'NE',
      'NH',
      'NJ',
      'NM',
      'NV',
      'NY',
      'OH',
      'OK',
      'OR',
      'PA',
      'PR',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VA',
      'VI',
      'VT',
      'WA',
      'WI',
      'WV',
      'WY'
    ],
    texasREPList: (process.env.NODE_ENV === 'development'
      ? [{ puct: 22222, name: 'TEST' }]
      : []
    ).concat([
      { puct: 10166, name: ' NEC Co-op Energy' },
      { puct: 10166, name: ' NEC RETAIL' },
      {
        puct: 10166,
        name: ' NUECES ELECTRIC COOPERATIVE (DISTRIBUTION SERVICE PROVIDER)'
      },
      { puct: 10260, name: '174 POWER GLOBAL RETAIL TEXAS LLC' },
      { puct: 10087, name: '3000 Energy Corp' },
      { puct: 10041, name: '4CHANGE' },
      { puct: 10041, name: '4CHANGE ENERGY' },
      { puct: 10137, name: 'ACACIA ENERGY' },
      { puct: 10129, name: 'ACCEL ENERGY' },
      { puct: 10078, name: 'ACCENT ENERGY' },
      { puct: 10078, name: 'ACCENT ENERGY TEXAS LP' },
      { puct: 10014, name: 'AES NEWENERGY' },
      { puct: 10074, name: 'ALLIANCE POWER COMPANY LLC' },
      { puct: 10179, name: 'ALLTEX POWER & LIGHT' },
      { puct: 10278, name: 'ALMIKA SOLAR' },
      { puct: 10117, name: 'AMBIT' },
      { puct: 10117, name: 'AMBIT ENERGY' },
      { puct: 10117, name: 'AMBIT TEXAS LLC' },
      { puct: 10224, name: 'AMERICAN POWERNET MANAGEMENT LP' },
      { puct: 10076, name: 'AMERIPOWER LLC' },
      { puct: 10081, name: 'AMIGO ENERGY' },
      { puct: 10250, name: 'AMOS ENERGY INC' },
      { puct: 10278, name: 'Ampra Energy' },
      { puct: 10105, name: 'AP COMMERCIAL' },
      { puct: 10105, name: 'AP GAS & ELECTRIC (TX) LLC' },
      { puct: 10105, name: 'APG&E' },
      { puct: 10105, name: 'APG&E ENERGY SOLUTIONS' },
      { puct: 10079, name: 'APN STARFIRST LP' },
      { puct: 10004, name: 'ASSURANCE ENERGY' },
      { puct: 10263, name: 'ATG Clean Energy' },
      { puct: 10263, name: 'ATG CLEAN ENERGY HOLDINGS INC' },
      { puct: 10227, name: 'Axon Power' },
      { puct: 10227, name: 'AXON POWER & GAS LLC' },
      { puct: 10278, name: 'Beyond Energy' },
      { puct: 10282, name: 'BLUE STAR ENERGY LLC' },
      { puct: 10038, name: 'BP ENERGY COMPANY' },
      { puct: 10290, name: 'BRANCH ENERGY' },
      { puct: 10290, name: 'BRANCH ENERGY HOLDINGS INC' },
      { puct: 10290, name: 'Branch Energy Technologies Inc' },
      { puct: 10129, name: 'BRIGHTSTAR POWER ' },
      { puct: 10140, name: 'BRILLIANT ENERGY LLC' },
      { puct: 10137, name: 'BROOKLET ENERGY DISTRIBUTION LLC' },
      { puct: 10266, name: 'Bulb' },
      { puct: 10266, name: 'BULB US LLC' },
      { puct: 10168, name: 'BUSHEIDO ENERGY LLC' },
      { puct: 10017, name: 'CALPINE ENERGY SOLUTIONS LLC' },
      { puct: 10015, name: 'CALPINE POWER AMERICA LLC' },
      { puct: 10098, name: 'CHAMPION ENERGY INDUSTRIAL SERVICES I' },
      { puct: 10098, name: 'CHAMPION ENERGY INDUSTRIAL SERVICES II' },
      { puct: 10098, name: 'CHAMPION ENERGY INDUSTRIAL SERVICES III' },
      { puct: 10098, name: 'CHAMPION ENERGY SERVICES LLC' },
      { puct: 10260, name: 'CHARIOT ENERGY' },
      { puct: 10216, name: 'Circular Energy' },
      { puct: 10177, name: 'CIRRO ENERGY' },
      { puct: 10237, name: 'CITIGROUP ENERGY INC' },
      { puct: 10268, name: 'CleanSky Energy' },
      { puct: 10129, name: 'CLEARVIEW ELECTRIC INC' },
      { puct: 10129, name: 'CLEARVIEW ENERGY' },
      { puct: 10286, name: 'CLYDE ENERGY' },
      { puct: 10177, name: 'COMPASSION ENERGY' },
      { puct: 10037, name: 'CONOCOPHILLIPS COMPANY' },
      { puct: 10179, name: 'CONSERVICE ENERGY' },
      { puct: 10014, name: 'Constellation' },
      { puct: 10014, name: 'CONSTELLATION NEWENERGY INC' },
      { puct: 10025, name: 'CORAL POWER LLC' },
      { puct: 10025, name: 'CORAL POWER SOLUTIONS' },
      { puct: 10174, name: 'COST PLUS ENERGY' },
      { puct: 10023, name: 'CPL' },
      { puct: 10011, name: 'CPL BUSINESS' },
      { puct: 10023, name: 'CPL RETAIL ENERGY' },
      { puct: 10023, name: 'CPL RETAIL ENERGY LP' },
      { puct: 10281, name: 'DECLARATION ENERGY LLC' },
      { puct: 10265, name: 'DEMAND CONTROL 2 LLC' },
      { puct: 10040, name: 'DIRECT ENERGY' },
      { puct: 10011, name: 'DIRECT ENERGY BUSINESS LLC' },
      { puct: 10040, name: 'DIRECT ENERGY BUSINESS SERVICES' },
      { puct: 10040, name: 'DIRECT ENERGY LP' },
      { puct: 10040, name: 'DIRECT ENERGY MULTI-FAMILY' },
      { puct: 10177, name: 'Discount Power' },
      { puct: 10082, name: 'DOW HYDROCARBONS AND RESOURCES LLC' },
      { puct: 10078, name: 'DYNOWATT' },
      { puct: 10078, name: 'DYNOWATT POWERED BY ACCENT ENERGY' },
      { puct: 10064, name: 'EAGLE ENERGY' },
      { puct: 10153, name: 'EDF ENERGY SERVICES LLC' },
      { puct: 10165, name: 'EIF CHANNELVIEW RETAIL ENERGY LLC' },
      { puct: 10231, name: 'ELECTRANET POWER LLC' },
      { puct: 10275, name: 'ELECTRIC E POWER MARKETING LLC' },
      { puct: 10246, name: 'ELIGO ENERGY TX LLC' },
      { puct: 10201, name: 'ENCOA' },
      { puct: 10274, name: 'ENERGY HARBOR LLC' },
      { puct: 10276, name: 'ENERGY MONGER LLC' },
      { puct: 10064, name: 'Energy Rewards' },
      { puct: 10259, name: 'Energy to Go' },
      { puct: 10168, name: 'ENERGY TRANSFER RETAIL POWER LLC' },
      { puct: 10053, name: 'ENGIE RESOURCES LLC' },
      { puct: 10204, name: 'ENGIE RETAIL LLC' },
      { puct: 10197, name: 'ENTRUST ENERGY INC' },
      { puct: 10205, name: 'EPIQ ENERGY' },
      { puct: 10242, name: 'EROCK ON-SITE LLC' },
      { puct: 10262, name: 'Evolve' },
      { puct: 10262, name: 'Evolve Energy' },
      { puct: 10262, name: 'Evolve Retail Energy LLC' },
      { puct: 10011, name: 'EXPERT ENERGY' },
      { puct: 10041, name: 'Express Energy' },
      { puct: 10054, name: 'EXXONMOBIL POWER AND GAS SERVICES INC' },
      { puct: 10008, name: 'FIRST CHOICE POWER' },
      { puct: 10008, name: 'FIRST CHOICE POWER LLC' },
      { puct: 10248, name: 'FREEPOINT ENERGY SOLUTIONS LLC' },
      { puct: 10169, name: 'FRONTIER UTILITIES' },
      { puct: 10169, name: 'FRONTIER UTILITIES LLC' },
      { puct: 10081, name: 'FULCRUM RETAIL ENERGY LLC' },
      { puct: 10244, name: 'GBPOWER LLC' },
      { puct: 10234, name: 'GERDAU AMERISTEEL ENERGY INC' },
      { puct: 10027, name: 'GEXA CORP' },
      { puct: 10027, name: 'GEXA ENERGY' },
      { puct: 10027, name: 'GEXA ENERGY CORP' },
      { puct: 10027, name: 'GEXA ENERGY LP' },
      { puct: 10027, name: 'GEXA ENERGY.COM' },
      { puct: 10244, name: 'G-POWER' },
      { puct: 10263, name: 'Green Direct' },
      { puct: 10263, name: 'GREEN ENERGY EXCHANGE' },
      { puct: 10009, name: 'GREEN MOUNTAIN ENERGY COMPANY' },
      { puct: 10243, name: 'GRIDDY ENERGY LLC' },
      { puct: 10256, name: 'GridPlus Energy' },
      { puct: 10256, name: 'GridPlus Energy Connect' },
      { puct: 10256, name: 'GRIDPLUS TEXAS INC' },
      { puct: 10098, name: 'Hello Energy' },
      { puct: 10251, name: 'HERITAGE POWER LLC' },
      { puct: 10092, name: 'HUDSON ENERGY SERVICES LLC' },
      { puct: 10277, name: 'HYBRID POWER LLC' },
      { puct: 10267, name: 'Iberdrola' },
      { puct: 10267, name: 'IBERDROLA SOLUTIONS LLC' },
      { puct: 10267, name: 'Iberdrola Texas' },
      { puct: 10267, name: 'Iberdrola USA' },
      { puct: 10078, name: 'IGS ENERGY' },
      { puct: 10179, name: 'ILUMINAR ENERGY LLC' },
      { puct: 10223, name: 'INFUSE ENERGY LLC' },
      { puct: 10269, name: 'INSPIRE ENERGY HOLDINGS LLC' },
      { puct: 10289, name: 'IRONHORSE POWER SERVICES LLC' },
      { puct: 10278, name: 'JP ENERGY RESOURCES LLC' },
      { puct: 10193, name: 'JP MORGAN VENTURES ENERGY CORPORATION' },
      { puct: 10052, name: 'JUST ENERGY' },
      { puct: 10052, name: 'JUST ENERGY RGV' },
      { puct: 10052, name: 'JUST ENERGY TEXAS LP' },
      { puct: 10118, name: 'Liberty Power' },
      { puct: 10259, name: 'Lone Star Energy' },
      { puct: 10118, name: 'LPT LLC' },
      { puct: 10118, name: 'LPT SP LLC' },
      { puct: 10043, name: 'LUMINANT ET SERVICES COMPANY LLC' },
      { puct: 10225, name: 'MERRILL LYNCH COMMODITIES INC' },
      { puct: 10233, name: 'MIDAMERICAN ENERGY SERVICES LLC' },
      { puct: 10141, name: 'Milestone Energy' },
      { puct: 10229, name: 'MINOTAUR LEGACY GROUP LLC' },
      { puct: 10110, name: 'Mio Electric' },
      {
        puct: 10273,
        name: 'MITSUI & CO ENERGY MARKETING AND SERVICES (USA) INC'
      },
      { puct: 10261, name: 'MORGAN STANLEY CAPITAL GROUP INC' },
      { puct: 10174, name: 'MP2 ENERGY TEXAS LLC' },
      { puct: 10236, name: 'MQE LLC' },
      { puct: 10236, name: 'My Quest Energy' },
      { puct: 10283, name: 'NATIONAL GAS & ELECTRIC TEXAS LLC' },
      { puct: 10085, name: 'NEUMIN PRODUCTION COMPANY' },
      { puct: 10040, name: 'NEW LEAF ENERGY ' },
      { puct: 10259, name: 'New Power Texas' },
      { puct: 10014, name: 'NEWENERGY' },
      { puct: 10019, name: 'NEXTERA ENERGY MARKETING LLC' },
      { puct: 10207, name: 'NORTH AMERICAN POWER AND GAS LLC' },
      { puct: 10126, name: 'NOVA ELECTRIC CO' },
      { puct: 10137, name: 'NOW POWER' },
      { puct: 10016, name: 'OCCIDENTAL POWER MARKETING LP' },
      { puct: 10262, name: 'Octopus' },
      { puct: 10262, name: 'Octopus Energy' },
      { puct: 10280, name: 'OHMCONNECT TEXAS LLC' },
      { puct: 10285, name: 'OLIN CORPORATION' },
      { puct: 10232, name: 'Operators Electric' },
      { puct: 10170, name: 'OUR ENERGY LLC' },
      { puct: 10110, name: 'PAGA MENOS' },
      { puct: 10208, name: 'PARTNERS NEW ENERGY LLC' },
      { puct: 10110, name: 'PAYLESS POWER' },
      { puct: 10177, name: 'PENNYWISE ' },
      { puct: 10177, name: 'PENNYWISE POWER ' },
      { puct: 10087, name: 'PENSTAR POWER' },
      { puct: 10087, name: 'PENSTAR POWER LLC' },
      { puct: 10087, name: 'PENSTAR POWER LLC 1' },
      { puct: 10278, name: 'Peso Power' },
      { puct: 10284, name: 'PILOT POWER GROUP LLC' },
      { puct: 10228, name: 'Pogo Energy' },
      { puct: 10228, name: 'POGO ENERGY LLC' },
      { puct: 10064, name: 'POWER HOUSE ENERGY' },
      { puct: 10255, name: 'POWER OF TEXAS' },
      { puct: 10255, name: 'POWER OF TEXAS HOLDINGS INC' },
      { puct: 10259, name: 'PowerNext' },
      { puct: 10280, name: 'POWERPASS' },
      { puct: 10179, name: 'PREMIUM POWER & LIGHT' },
      { puct: 10278, name: 'Prepay Power' },
      { puct: 10216, name: 'PRO POWER PROVIDERS LLC' },
      { puct: 10205, name: 'PRONTO POWER ' },
      { puct: 10259, name: 'PULSE POWER LLC' },
      { puct: 10232, name: 'Pumpjack Power' },
      { puct: 10232, name: 'Pumpjack Power LLC' },
      { puct: 10264, name: 'Ranchero Power' },
      { puct: 10232, name: 'Reciprocal Energy Solutions' },
      { puct: 10007, name: 'RELIANT' },
      { puct: 10007, name: 'RELIANT ENERGY' },
      { puct: 10007, name: 'RELIANT ENERGY BUSINESS SERVICES' },
      { puct: 10007, name: 'RELIANT ENERGY RETAIL SERVICES LLC' },
      { puct: 10239, name: 'REP ACE POWER LLC' },
      { puct: 10279, name: 'RHYTHM' },
      { puct: 10279, name: 'RHYTHM OPS LLC' },
      { puct: 10169, name: 'RODEO ENERGY' },
      { puct: 10249, name: 'SFE ENERGY TEXAS INC' },
      { puct: 10104, name: 'SG&E' },
      { puct: 10025, name: 'SHELL ENERGY NORTH AMERICA (US) LP (SENA)' },
      { puct: 10051, name: 'SMART PREPAID ELECTRIC' },
      { puct: 10253, name: 'SMARTENERGY HOLDINGS LLC' },
      { puct: 10169, name: 'SOL ENERGY' },
      { puct: 10247, name: 'SOLAR STAR PLANO I LLC' },
      { puct: 10264, name: 'SOUTHERN FEDERAL POWER LLC' },
      { puct: 10126, name: 'SOUTHWEST POWER & LIGHT' },
      { puct: 10046, name: 'SPARK ENERGY LLC' },
      { puct: 10014, name: 'STARTEX POWER' },
      { puct: 10202, name: 'STAT Energy LLC' },
      { puct: 10104, name: 'STREAM ENERGY' },
      { puct: 10104, name: 'STREAM SPE LTD' },
      { puct: 10205, name: 'SUMMER ENERGY LLC' },
      { puct: 10254, name: 'Super Power' },
      { puct: 10257, name: 'TALEN ENERGY MARKETING LLC' },
      { puct: 10051, name: 'TARA ENERGY' },
      { puct: 10051, name: 'TARA ENERGY LLC' },
      { puct: 10081, name: 'TARA ENERGY RESOURCES' },
      { puct: 10081, name: 'TARA POWER' },
      { puct: 10035, name: 'TENASKA POWER SERVICES CO' },
      { puct: 10061, name: 'TENASKA TOPS REP LP' },
      { puct: 10201, name: 'TERM ENERGY ' },
      { puct: 10201, name: 'TERM POWER & GAS LLC (TERM)' },
      { puct: 10141, name: 'Texans Energy' },
      { puct: 10263, name: 'Texas Power Outlet' },
      { puct: 10065, name: 'TEXAS RETAIL ENERGY LLC' },
      { puct: 10126, name: 'TEXPO ENERGY' },
      { puct: 10126, name: 'TEXPO POWER LP' },
      { puct: 10204, name: 'THINK ENERGY' },
      { puct: 10268, name: 'TITAN GAS AND POWER' },
      { puct: 10268, name: 'TITAN GAS LLC' },
      { puct: 10270, name: 'TOMORROW ENERGY CORP' },
      { puct: 10061, name: 'TOPS I' },
      { puct: 10241, name: 'TOTAL GAS & POWER NORTH AMERICA INC' },
      { puct: 10258, name: 'TOWN SQUARE ENERGY LLC' },
      { puct: 10035, name: 'TPS 4' },
      { puct: 10035, name: 'TPS I' },
      { puct: 10035, name: 'TPS II' },
      { puct: 10035, name: 'TPS III' },
      { puct: 10272, name: 'TRAILSTONE ENERGY MARKETING LLC' },
      { puct: 10064, name: 'TRIEAGLE ENERGY LP' },
      { puct: 10064, name: 'TRIEAGLE ENERGY SERVICES' },
      { puct: 10287, name: 'TRUE COMMODITIES LLC' },
      { puct: 10287, name: 'TRUE POWER' },
      { puct: 10287, name: 'True Prepaid' },
      { puct: 10004, name: 'TXU ENERGY' },
      { puct: 10004, name: 'TXU ENERGY RETAIL COMPANY LLC' },
      { puct: 10110, name: 'UNO ENERGY' },
      { puct: 10052, name: 'US ENERGY SAVINGS CORP' },
      { puct: 10177, name: 'US RETAILERS LLC' },
      { puct: 10210, name: 'V247 POWER CORPORATION' },
      { puct: 10041, name: 'VALUE BASED BRANDS LLC' },
      { puct: 10271, name: 'VARSITY ENERGY LLC' },
      { puct: 10041, name: 'Veteran Energy' },
      { puct: 10064, name: 'Viridian Energy' },
      { puct: 10137, name: 'VITAL POWER' },
      { puct: 10226, name: 'VOLT ELECTRICITY PROVIDER LP' },
      { puct: 10264, name: 'Watt Bro' },
      { puct: 10254, name: 'Windrose' },
      { puct: 10254, name: 'WINDROSE ENERGY' },
      { puct: 10254, name: 'WINDROSE POWER AND GAS LLC' },
      { puct: 10141, name: 'World Power & Gas LP' },
      { puct: 10022, name: 'WTU' },
      { puct: 10011, name: 'WTU BUSINESS' },
      { puct: 10022, name: 'WTU RETAIL ENERGY ' },
      { puct: 10022, name: 'WTU RETAIL ENERGY LP' },
      { puct: 10203, name: 'XOOM ENERGY TEXAS LLC' },
      { puct: 10286, name: 'YENTA ENERGY LP' },
      { puct: 10126, name: 'YEP' },
      { puct: 10110, name: 'YOUNG ENERGY LLC' },
      { puct: 10105, name: 'ZIP ENERGY' }
    ]),
    monthList: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ]
  }
};
