import React, { useEffect, useRef, useState } from 'react';
import ConnectElectric from '../ConnectElectric';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withPrefix } from 'gatsby';
import { navigate } from 'gatsby-link';
import { useQueryParam } from 'gatsby-query-params';
import {
  Parallax,
  ParallaxBanner,
  ParallaxProvider
} from 'react-scroll-parallax';
import {
  ArrowForward,
  ChevronRight,
  ExpandMore,
  Info,
  InfoOutlined
} from '@mui/icons-material';

import {
  saveServiceAddress,
  getCurrentRoute,
  getCookie,
  scrollToElementInPage
} from '../../api/dataApi';

import * as webSocketActions from '../../redux/actions/webSocketActions';
import * as serviceAddressActions from '../../redux/actions/serviceAddressActions';

import { electricData } from '../../mock/electric';

import AddressSearch from '../AddressSearch';
import AddressSearchPopup from '../AddressSearch/AddressSearchPopup';
import Electric6MonthAvgCompare from '../Electric6MonthAvgCompare';
import TermTypePopup from '../ConnectElectric/TermTypePopup';

import '../Landing_2024/index.css';
import './index.css';
import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';

export function ProviderLogos() {
  return (
    <>
      <img
        src="https://sc-cdn-public.s3.us-west-2.amazonaws.com/75593_Email_reliant-energy-logos-ideRUj2Mu-.svg"
        alt="Reliant Energy"
      />
      <img
        src="https://sc-cdn-public.s3.us-west-2.amazonaws.com/76651_gme2.svg"
        alt="Green Mountain Energy"
      />
      <img
        src="https://sc-cdn-public.s3.us-west-2.amazonaws.com/75597_Email_DirectEnergy.png"
        alt="Direct Energy"
      />{' '}
      <img
        src="https://sc-cdn-public.s3.us-west-2.amazonaws.com/76351_Email_drawing.svg"
        alt="Cirro Energy"
      />
      <img
        src="https://sc-cdn-public.s3.us-west-2.amazonaws.com/85647_Email_rhythm-logo.svg"
        alt="Rhythm Energy"
      />
      <img
        src="https://sc-cdn-public.s3.us-west-2.amazonaws.com/85755_Email_65da7cc6c0892ef66dfec234_OhmConnect-Logo%201.svg"
        alt="OhmConnect"
      />
      {/* <img
        src="https://sc-cdn-public.s3.us-west-2.amazonaws.com/77671_Veteran%20Energy.svg"
        alt="Veteran Energy"
      />
      <img
        src="https://sc-cdn-public.s3.us-west-2.amazonaws.com/85467_express-logo.svg"
        alt="Express Energy"
      /> */}
      <img
        src="https://sc-cdn-public.s3.us-west-2.amazonaws.com/76612_gexa_logo_stacked_hires_(color)_trans_back.svg"
        alt="Gexa Energy"
      />
      <img
        src="https://sc-cdn-public.s3.us-west-2.amazonaws.com/76071_Frontier-Utilities-Corporate-Logo.svg"
        alt="Frontier Utilities"
      />
      {/* <img
        src="https://sc-cdn-public.s3.us-west-2.amazonaws.com/77690_4change_energy_logoV2-01.svg"
        alt="4Change Energy"
      /> */}
      <img
        src="https://sc-cdn-public.s3.us-west-2.amazonaws.com/85468_logo-payless-color.svg"
        alt="Payless Power"
      />
      <img
        src="https://sc-cdn-public.s3.us-west-2.amazonaws.com/76077_dp2.svg"
        alt="Discount Power"
      />
      <img
        src="https://sc-cdn-public.s3.us-west-2.amazonaws.com/AE-Texas-Logo-w-Leaf-1024x464.png"
        alt="AETexas"
      />
    </>
  );
}

function FAQ({ question, answer }) {
  const [expanded, setExpanded] = useState(false);

  return (
    <div
      itemscope
      itemprop="mainEntity"
      itemtype="https://schema.org/Question"
      className={'question' + (expanded ? ' expanded' : '')}>
      <h5 itemprop="name" onClick={() => setExpanded(!expanded)}>
        {question}{' '}
        <IconButton>
          <ExpandMore />
        </IconButton>
      </h5>
      <div
        itemscope
        itemprop="acceptedAnswer"
        itemtype="https://schema.org/Answer">
        <p itemprop="text">{answer}</p>
      </div>
    </div>
  );
}

function BlogEntry({ title, alt, imageUrl, link }) {
  return (
    <div className="blog-entry">
      <article itemscope itemtype="https://schema.org/BlogPosting">
        <img src={imageUrl} alt={alt} itemprop="image" />

        <h4 itemprop="headline">
          <a href={link} itemprop="url">
            {title}
          </a>
        </h4>
        <Button href={link} itemprop="url">
          Read more <ArrowForward />
        </Button>
      </article>
    </div>
  );
}

function Demo({
  actions,
  city,
  webSocket,
  serviceAddress,
  siteConfig,
  noHeader,
  preFilter,
  preFilterLabel,
  headerIfName
}) {
  const checking = useRef();
  const initted = useRef();
  const [showAddressSearch, setShowAddressSearch] = useState(null);
  const [hasZipStored, setHasZipStored] = useState(getCookie('sc-zip-code'));
  const [hideHeader, setHideHeader] = useState(null);
  const uc = useQueryParam('uc');
  const [show6MonthAvg, setShow6MonthAvg] = useState(null);
  const [showSummer, setShowSummer] = useState(null);
  const [showTermTypePopup, setShowTermTypePopup] = useState(null);
  const [showProofOfServiceSample, setShowProofOfServiceSample] =
    useState(false);

  const simple = useQueryParam('simple') === '1';
  const zipEntered = useQueryParam('zipEntered') === '1';
  const scrollTimeout = useRef();

  useEffect(() => {
    actions.setLandingPage({ route: getCurrentRoute(), city });
    if (hasZipStored) {
      actions.pageLoading(true, 'Checking rates...');
    }
    //actions.serviceAddressUpdated({ ...electricData[city], mock: true });
  }, []);

  useEffect(() => {
    if (zipEntered) {
      setHideHeader(true);
    } else {
      setHideHeader(false);
    }
    console.log('zip entered', zipEntered);
  }, [zipEntered]);

  useEffect(() => {
    if (
      uc &&
      uc === 'IG-Aff' &&
      webSocket &&
      webSocket.initted &&
      webSocket.ajaxConnected &&
      !show6MonthAvg
    ) {
      setShow6MonthAvg(true);
    }
  }, [uc, webSocket]);

  useEffect(() => {
    if (
      uc &&
      uc === 'IG-Summer' &&
      webSocket &&
      webSocket.initted &&
      webSocket.ajaxConnected &&
      !showSummer
    ) {
      setShowSummer(true);
    }
  }, [uc, webSocket]);

  useEffect(() => {
    if (
      webSocket &&
      webSocket.initted &&
      webSocket.ajaxConnected &&
      !webSocket.hasServiceAddress &&
      !checking.current &&
      (!serviceAddress || serviceAddress.mock) &&
      city
    ) {
      checking.current = true;

      actions.pageLoading(true, 'Checking rates...');

      saveServiceAddress('', '', city, 'TX', '', 11).then((result) => {
        actions.serviceAddressUpdated({ ...result, force: true });
        actions.pageLoading(false);
      });
    }
  }, [webSocket]);

  useEffect(() => {
    if (
      serviceAddress &&
      serviceAddress.Address &&
      serviceAddress.Address.Zip
    ) {
      if (!hasZipStored && (getCookie('sc-zip-code') || city)) {
        scrollTimeout.current = setTimeout(() => {
          setHasZipStored(true);
          scrollToPlans(false);
        }, 500);
      }

      actions.pageLoading(false);
    }
  }, [serviceAddress]);

  useEffect(() => {
    const observerOptions = {
      root: null, // viewport
      threshold: 0.2 // trigger when X% of the element is visible
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && entry.target.id) {
          entry.target.classList.add('in-view');

          const connector = document.getElementById(
            'connector' + entry.target.id.split('point')[1]
          );
          if (connector) {
            connector.classList.add('in-view');
          }
        }
      });
    }, observerOptions);

    // Target all .point elements with an ID
    const pointsWithIds = document.querySelectorAll('.point[id]');
    pointsWithIds.forEach((point) => observer.observe(point));

    // Cleanup observer on component unmount
    return () => {
      pointsWithIds.forEach((point) => observer.unobserve(point));
    };
  }, []);

  function scrollToPlans(instant, offset) {
    const bestHeader = document.getElementsByClassName('best-header')[0];
    const offerRow = document.getElementsByClassName('offer-row')[0];
    if (bestHeader && offerRow) {
      scrollToElementInPage(bestHeader, offset ? offset : -280, instant);
    }
  }

  if (typeof window === 'undefined') {
    actions.serviceAddressUpdated({ ...electricData[city], mock: true });
  }

  return (
    <div
      className={
        'sc-landing-wrapper sc-landing-2024 resident' +
        (hasZipStored &&
        (!serviceAddress ||
          !serviceAddress.Address ||
          !serviceAddress.Address.Zip)
          ? ' with-zip'
          : '') +
        (hasZipStored ||
        (serviceAddress && serviceAddress.Address && serviceAddress.Address.Zip)
          ? ' with-offers'
          : '') +
        (hideHeader ? ' hide-header' : '')
      }>
      <div className="hero property-welcome">
        <h1>Hello, Jennifer!</h1>
        <h3>Welcome to Palladium at the Jackson</h3>

        <h5>Electric Service - Action Required</h5>

        <p>
          Your lease requires that you provide the property with Proof of
          Service showing that you have set up Electric Service in your name
          prior to moving in. If you use the SmarterChoice shopping platform
          below to set up your service, we will automatically send the ‘Proof of
          Service’ documentation that is required by your lease to the property
          so you don’t have to.
        </p>
      </div>

      <div className="best-header all">
        <h2>
          {serviceAddress &&
          serviceAddress.OfferDictionary &&
          serviceAddress.OfferDictionary.Electric ? (
            <span>
              <em>
                All electric plans for{' '}
                <strong>4101 Viridian Village Dr Apt 2117</strong>
              </em>
            </span>
          ) : null}
        </h2>
      </div>

      <ConnectElectric />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    siteConfig: state.commonData.siteConfig
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch),
      setLandingPage: bindActionCreators(
        webSocketActions.setLandingPage,
        dispatch
      ),
      serviceAddressUpdated: bindActionCreators(
        serviceAddressActions.serviceAddressUpdated,
        dispatch
      )
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Demo);
