// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ccpa-js": () => import("./../../../src/pages/ccpa.js" /* webpackChunkName: "component---src-pages-ccpa-js" */),
  "component---src-pages-ccpa-request-js": () => import("./../../../src/pages/ccpa-request.js" /* webpackChunkName: "component---src-pages-ccpa-request-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-copyright-form-js": () => import("./../../../src/pages/copyright-form.js" /* webpackChunkName: "component---src-pages-copyright-form-js" */),
  "component---src-pages-demo-js": () => import("./../../../src/pages/demo.js" /* webpackChunkName: "component---src-pages-demo-js" */),
  "component---src-pages-electric-checkout-js": () => import("./../../../src/pages/electric-checkout.js" /* webpackChunkName: "component---src-pages-electric-checkout-js" */),
  "component---src-pages-electric-rates-js": () => import("./../../../src/pages/electric-rates.js" /* webpackChunkName: "component---src-pages-electric-rates-js" */),
  "component---src-pages-energy-101-js": () => import("./../../../src/pages/energy-101.js" /* webpackChunkName: "component---src-pages-energy-101-js" */),
  "component---src-pages-fixed-rates-js": () => import("./../../../src/pages/fixed-rates.js" /* webpackChunkName: "component---src-pages-fixed-rates-js" */),
  "component---src-pages-green-energy-js": () => import("./../../../src/pages/green-energy.js" /* webpackChunkName: "component---src-pages-green-energy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-know-your-rights-js": () => import("./../../../src/pages/know-your-rights.js" /* webpackChunkName: "component---src-pages-know-your-rights-js" */),
  "component---src-pages-landing-2024-js": () => import("./../../../src/pages/landing-2024.js" /* webpackChunkName: "component---src-pages-landing-2024-js" */),
  "component---src-pages-landing-2025-js": () => import("./../../../src/pages/landing-2025.js" /* webpackChunkName: "component---src-pages-landing-2025-js" */),
  "component---src-pages-mobile-login-js": () => import("./../../../src/pages/mobile-login.js" /* webpackChunkName: "component---src-pages-mobile-login-js" */),
  "component---src-pages-need-to-know-js": () => import("./../../../src/pages/need-to-know.js" /* webpackChunkName: "component---src-pages-need-to-know-js" */),
  "component---src-pages-payment-complete-js": () => import("./../../../src/pages/payment-complete.js" /* webpackChunkName: "component---src-pages-payment-complete-js" */),
  "component---src-pages-prepaid-js": () => import("./../../../src/pages/prepaid.js" /* webpackChunkName: "component---src-pages-prepaid-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-rescue-js": () => import("./../../../src/pages/rescue.js" /* webpackChunkName: "component---src-pages-rescue-js" */),
  "component---src-pages-set-address-js": () => import("./../../../src/pages/set-address.js" /* webpackChunkName: "component---src-pages-set-address-js" */),
  "component---src-pages-smt-js": () => import("./../../../src/pages/smt.js" /* webpackChunkName: "component---src-pages-smt-js" */),
  "component---src-pages-switch-js": () => import("./../../../src/pages/switch.js" /* webpackChunkName: "component---src-pages-switch-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-texas-disclosure-js": () => import("./../../../src/pages/texas-disclosure.js" /* webpackChunkName: "component---src-pages-texas-disclosure-js" */),
  "component---src-pages-tou-js": () => import("./../../../src/pages/tou.js" /* webpackChunkName: "component---src-pages-tou-js" */),
  "component---src-pages-tx-js": () => import("./../../../src/pages/tx.js" /* webpackChunkName: "component---src-pages-tx-js" */)
}

