import React, { Fragment } from 'react';

import clsx from 'clsx';

import { Paper, List, ListItem, ListItemText } from '@mui/material';

import { connect } from 'react-redux';

import { navigate, withPrefix } from 'gatsby-link';
import AddressSearch from '../AddressSearch';

function Footer({ siteConfig, hideZipForm }) {
  return (
    <>
      {!hideZipForm && (
        <div className="main-footer-zip">
          <div className="main-footer-zip-inner">
            <h3>Ready to find a cheap electricity plan?</h3>
            <AddressSearch
              afterAddressSelected={() => {
                navigate('/electric-rates');
              }}
              hideAddressSearchButton
            />
          </div>
        </div>
      )}
      <div className="main-footer">
        <ul>
          <li>
            <img
              style={{
                maxWidth: siteConfig.logoMaxWidth
              }}
              className="logo"
              alt={siteConfig.brand}
              src={withPrefix(siteConfig.logoFooter)}
            />
          </li>
          <li className="contact">
            <strong>Contact Us</strong>
            <br />
            Email: <a href={'mailto:' + siteConfig.email}>{siteConfig.email}</a>
            <br />
            Phone: <a href={'tel:' + siteConfig.phone}>{siteConfig.phone}</a>
          </li>
          <li>
            <a href={withPrefix('privacy-policy/')}>Privacy Policy</a>
            <a href={withPrefix('terms/')}>Terms</a>
            <a href={withPrefix('ccpa/')}>California Privacy Act</a>
            <a href={withPrefix('texas-disclosure/')}>
              Texas Broker Disclosure
            </a>
          </li>
        </ul>
        <div className="bottom">
          &copy; {new Date().getFullYear()} {siteConfig.copyright}. All rights
          reserved.
        </div>
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {
    siteConfig: state.commonData.siteConfig
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {}
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
