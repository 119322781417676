import React from 'react';
import { withPrefix } from 'gatsby-link';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function ProofOfServiceExamplePopup({ closeFunc, title }) {
  return (
    <Dialog
      open={true}
      aria-labelledby="form-dialog-title"
      className="proof-of-service-example-popup"
      fullWidth={true}>
      <DialogTitle id="form-dialog-title">
        <IconButton
          style={{ float: 'right' }}
          aria-label="close"
          onClick={() => {
            closeFunc();
          }}>
          <CloseIcon />
        </IconButton>
        <img src={withPrefix('/images/logo-icon.svg')} className="logo-icon" />
        {title ? title : 'Term Length Information'}
      </DialogTitle>
      <DialogContent width="100%" style={{ paddingTop: '20px' }}>
        <img
          src={withPrefix('/images/sc-landing/email.png')}
          alt="Proof of Service Example"
        />

        <div style={{ textAlign: 'center', paddingTop: '20px' }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              closeFunc();
            }}>
            Continue
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default ProofOfServiceExamplePopup;
