import React, { useState } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withPrefix } from 'gatsby';

import * as webSocketActions from '../../../redux/actions/webSocketActions';

import { Button } from '@mui/material';

import TOC from '../../TOC';
import ZipCodeField from '../ZipCodeField';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';

import '../index.css';
import './index.css';
import { ProviderLogos } from '../../Landing_2024';

function Article({ siteConfig, serviceAddress }) {
  const [showTrap, setShowTrap] = useState(null);
  return (
    <div className="article-container">
      <h1>
        <div>Know Your Rights - Early Termination Fees (ETF)</div>
      </h1>
      <div className="page-container text-container full know-your-rights">
        <h5 className="est-read-time">
          Published: 11/15/2024 | Read time {'~ '}10 min
        </h5>

        <div className="page">
          <div className="inner">
            <div className="article-text" style={{ width: '100%' }}>
              <h3>
                "If I'm moving, do I have to pay an Early Termination Fee?"
              </h3>
              <p>
                <strong>
                  No – That’s because all electricity service contracts in Texas
                  are location-based and state law requires all providers to
                  waive Early Termination Fees when a customer is moving from
                  one address to another. This means you won’t be penalized for
                  canceling your current contract early if you're relocating to
                  a new home or apartment.
                </strong>
              </p>
              <p>The applicable statute text is below:</p>
              <div className="excerpt">
                <p>
                  CHAPTER 25. SUBSTANTIVE RULES APPLICABLE TO ELECTRIC SERVICE
                  PROVIDERS. (effective date 01/06/2022)
                </p>
                <blockquote>
                  <p>
                    Subchapter R. CUSTOMER PROTECTION RULES FOR RETAIL ELECTRIC
                    SERVICE.
                  </p>
                  <p>§25.475(c)(2)</p>
                  <p className="highlight">
                    (C) A contract is limited to service to a customer at a
                    location specified in the contract. If the customer moves
                    from the location, the customer is under no obligation to
                    continue the contract at another location. The REP may
                    require a customer to provide evidence that it is moving to
                    another location. There must be no early termination fee
                    assessed to the customer as a result of the customer’s
                    relocation if the customer provides a forwarding address
                    and, if required, reasonable evidence that the customer no
                    longer occupies the location specified in the contract.
                  </p>
                </blockquote>
              </div>
              <p>
                Statute link:{' '}
                <a
                  href={
                    'https://www.puc.texas.gov/agency/rulesnlaws/subrules/electric/25.475/25.475.pdf'
                  }>
                  https://www.puc.texas.gov/agency/rulesnlaws/subrules/electric/25.475/25.475.pdf
                </a>
              </p>
              <p>§25.475(c)(2)(C) can be found near the top of page 3.</p>
              <p style={{ marginBottom: '-20px' }}>
                <strong>This same statute also states in §25.475(f)(4):</strong>
              </p>
              <div className="excerpt">
                <p>
                  (C) The TOS from the provider must also include: A statement
                  of the customer’s ability to terminate service without penalty
                  if the customer moves to another premises and provides
                  evidence that it is moving, if required, and a forwarding
                  address.{' '}
                </p>
              </div>
              <h2>
                Switching Providers Without Moving – an Early Termination Fee
                may apply
              </h2>
              <p>
                If you're planning to switch providers before your current
                contract expires, you will probably have to pay an Early
                Termination Fee to break your existing agreement.
              </p>
              <h2>Frequently Asked Questions </h2>
              <p style={{ marginTop: '20px' }}>
                <strong>What is an Early Termination Fee?</strong>
              </p>
              <p>
                An Early Termination Fee is a penalty imposed by a provider when
                a customer cancels their contract before the agreed-upon term
                ends. It is designed to discourage customers from ending their
                contracts early.
              </p>
              <p style={{ marginTop: '20px' }}>
                <strong>How much are they?</strong>
              </p>
              Early Termination Fees can vary significantly based on which
              provider you’re with, and sometimes even which plan you’re
              enrolled in.
              <p style={{ marginTop: '20px' }}>
                <strong>How do I find out what my ETF would be?</strong>
              </p>
              <p>
                You can find your Early Termination Fee (ETF) on the Electricity
                Facts Label (EFL) or in the Terms of Service (TOS) provided when
                you first enrolled in your plan. Alternatively, you can check
                your online account or contact your provider directly.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    siteConfig: state.commonData.siteConfig,
    serviceAddress: state.serviceAddress
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Article);
